<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
</style>

<template>
  <div class="box">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ title }}
      </h3>
      <div class="box-controls float-right">
        <div class="box-header-actions">
          <YearMonthDropdown
            class="d-sm-inline"
            :year="filterDateYear"
            :month="filterDateMonth"
            :applied="filterDateApply"
            :fullyear="filterDateFullYear"
            :two-months-checked="filterTwoMonth"
            @dateChanged="onDateFilterChanged"
            @fullYearClicked="onDateFilterFullYear"
            @clearRequested="onDateFilterClear"
            @twoMonthClicked="onTwoMonthFilter"
          />
        </div>
      </div>
    </div>
    <div class="box-body no-padding">
      <div class="row">
        <div class="col">
          <vuetable
            ref="vuetable"
            class="table table-hover"
            :api-url="`${apiUrl}?${params}`"
            :fields="fieldsLogs"
            :per-page="perPage"
            :http-options="{withCredentials: true}"
            track-by="_id"
            data-path="rows"
            pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData"
          />
        </div>
      </div>
    </div>
    <div class="box-footer table-footer">
      <vuetable-pagination
        ref="pagination"
        class="dataTables_paginate paging_simple_numbers float-right"
        @vuetable-pagination:change-page="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vuetable from "vuetable-2";
import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";

export default {
  name: "RocketLogs",
  components: {
    Vuetable,
    VuetablePagination,
    YearMonthDropdown,
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    apiUrl: {
      type: String,
      required: true
    },
    perPage: {
      type: Number,
      default: 10
    },
    fieldsLogs: {
      type: Array,
      default() {
        return [
          {
            title: "id",
            name: "_id"
          },
          {
            name: "createdAt",
            title: "Inicio"
          }
        ];
      }
    }
  },
  data() {
    return {
      filterDateApply: true,
      filterDateFullYear: false,
      filterTwoMonth: false,
      filterDateYear: 2023,
      filterDatePrevYear: 2023,
      filterDateMonth: 2,
      filterDatePrevMonth: 1,
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    params() {
      const {
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
        filterDateYear = null,
        filterDatePrevYear = null,
        filterDateMonth = null,
        filterDatePrevMonth = null,
      } = this;

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth =  filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        year,
        month,
        toyear,
        tomonth
      };
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      const p = new URLSearchParams(filtredParams).toString();
      // console.log(p);
      return p;
    }
  },
  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onDateFilterChanged(year, month) {
      console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = false;
    },
    onDateFilterFullYear(year) {
      console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
      this.filterTwoMonth = false;
    },
    onDateFilterClear() {
      console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterTwoMonth = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.filterDateYear = curryear;
      this.filterDateMonth = currmonth;
      this.filterDatePrevYear = prevyear;
      this.filterDatePrevMonth = prevmonth;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = true;
    }
  },
  beforeMount() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
    this.filterDateMonth = now.getMonth() + 1;
    // console.log(this.apiUrl);
  },
};
</script>
