<template>
  <div class="row">
    <div class="col">
      <RktLogs
        :title="title"
        :api-url="`${API_ROOT}/api/logs`"
        :per_page="15"
        :fields-logs="fields"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { formatRelative } from "date-fns";
import RktLogs from "@/components/RktLogs.vue";

export default {
  components: {
    RktLogs
  },
  data() {
    return {
      title: "Registro de actividad",
      fields: [
        {
          name: "createdAt",
          title:"Fecha",
          formatter(value) {
            if (typeof value === "undefined" || !value) return "-";
            return formatRelative(new Date(value), new Date(), { locale: window.localeDateFns });
          },
          sortField: "createdAt",
          width:"20%"
        },
        {
          name:"keyword",
          title :"KEY",
          formatter(value) {
            if (typeof value === "undefined" || !value) return "-";
            return (`<small>${value}</small>`);
          },
          width:"15%"
        },
        {
          name: "email",
          title: "Email",
          width:"15%"
        },
        {
          name: "comment",
          title: "Evento"
        }
      ]
    };
  },
  computed: {
    ...mapState(["API_ROOT"])
  },
  mounted() {
    console.log("LOGS");
  }
};
</script>
